<template>
  <div class="chell">

    <div  class="template-wrapper"  :style="{background: data.color}">
      <div class="time" >
        {{momentFormat(data.StartTime, 'HH:mm') +' - ' }}
        {{ momentFormat(data.EndTime,'HH:mm')}}
      </div>
      <div>{{data.Subject}}</div>

      <!--      <div class="subject" style="margin-top: 3px;" v-if="data.Subject !== '.'&& data.state !== 'leave'">-->

      <!--        <div v-if="data.state=== 'draft'" class="" style="overflow: hidden; text-overflow: ellipsis; background-color: #FFFFFF; border-radius: 3px; padding: 1px ;color: #4361EE;">-->
      <!--          </div>-->
      <!--&lt;!&ndash;        <div v-else class="" style="overflow: hidden; text-overflow: ellipsis; background-color: #FFFFFF; border-radius: 3px; padding: 1px ;color: #00C49A; word-wrap:break-word;">&ndash;&gt;-->
      <!--&lt;!&ndash;          {{data.Subject}}</div>&ndash;&gt;-->
      <!--        &lt;!&ndash;      <b-alert&ndash;&gt;-->
      <!--        &lt;!&ndash;          variant="warning "&ndash;&gt;-->
      <!--        &lt;!&ndash;          class="p-2"&ndash;&gt;-->
      <!--        &lt;!&ndash;          show>&ndash;&gt;-->

      <!--        &lt;!&ndash;        <span class="text-dark">You haven't any swap request!</span>&ndash;&gt;-->
      <!--        &lt;!&ndash;      </b-alert>&ndash;&gt;-->


      <!--      </div>-->

      <!--      <div style="background-color: black; padding:2px; width: 57px;border-radius: 8px" v-if="data.applications_count !==0" class="text-white font-weight-bold font-weight-700">-->
      <!--        <span class="p-1"><feather-icon icon="UserIcon" class=""  /> <span>{{data.applications_count}}</span></span>-->
      <!--      </div>-->

      <!--    <div class="image"><img :src="getImage" /></div>-->
      <!--    <div class="description">{{data.Description}}</div><div class="footer" :style="{background: data.PrimaryColor}"></div></div>-->
    </div>


  </div>

  <!--     <h1>leave</h1>-->

</template>

<script>

import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import {BAlert} from 'bootstrap-vue'
export default {
  name: 'monthTemplate',
  mixins: [ErrorMessages, SuccessMessage, MomentMixin],
  comments:{
    BAlert,

  },
  data(){
    return{
      data:''
    }
  },

  methods:{
    getTimeString: function (value) {
      return instance.formatDate(value, { skeleton: 'hm' });
    }
  },
  mounted () {
    // if (this.data.applications_count !==0){
    //   this.data.color ='#EFA12C'
    // }
  }
}
</script>

<style>
.chell{
  width: 100% !important;
  height: 100% !important;

}
.e-appointment-details{
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
}

.template-wrapper{
  padding: 10px;
  height: 100%;
  font-size: 12px;

}

.borderBind{
  border-style: dashed;
  background-color: transparent;
  border-radius: 4px;
}
/* .subject{
} */



</style>
