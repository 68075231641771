<template>
    <div>
      <div v-if="selectedCell.length !==0" class="p-1">
        <div>

          <b-row>
            <div class="font-weight-bold mr-1 " v-if="selectedCell.Subject !== '.'">{{selectedCell.Subject}}</div>
            <b-badge size="sm" class="mr-1" v-if="selectedCell.is_covid_shift" variant="danger">
              COVID-19
            </b-badge>
            <b-badge size="sm" v-if="selectedCell.service.type ==='clinical'" :variant="'light-success'" class="text-capitalize">
                       {{selectedCell.service.type}}
                      </b-badge>
            <b-badge size="sm" v-if="selectedCell.service.type ==='non-clinical'" :variant="'light-info'" class="text-capitalize">
                        {{selectedCell.service.type}}
                      </b-badge>

          </b-row>
          <b-row>
            <div class="font-weight-bold mr-1  " v-if="selectedCell.shift_type">{{selectedCell.shift_type.name}}</div>


          </b-row>
          <b-row class="mt-1">
            <feather-icon icon="CalendarIcon" class="mr-1" />
            {{momentFormat(selectedCell.StartTime,'ddd DD MMMM YYYY')}}
            <!--                        {{moment().format("YYYY Do MM")}}-->
          </b-row>
          <b-row class="mt-1">
            <feather-icon icon="ClockIcon" class="mr-1"  />
            {{momentFormat(selectedCell.StartTime,'HH:mm')}} - {{momentFormat(selectedCell.EndTime,'HH:mm')}}
          </b-row>
          <b-row class="mt-1">
            <feather-icon icon="MapPinIcon" class="mr-1"  />
            {{selectedCell.practice.name}} <br>
            {{selectedCell.practice.address}}/{{selectedCell.practice.city}}
          </b-row>
          <b-row class="mt-1">
            <feather-icon icon="MailIcon" class="mr-1"  />
            {{selectedCell.practice.email}}
          </b-row>
<!--          <b-row class="mt-1" v-if="selectedCell.user">-->
<!--            <feather-icon icon="UsersIcon" class="mr-1"  />-->
<!--            {{selectedCell.user.first_name +' ' +selectedCell.user.last_name}}-->
<!--          </b-row>-->
          <b-row class="mt-1">
            <b-badge variant="secondary" v-if="selectedCell.state ==='Vacant'">
              £180.00 / £220.00
            </b-badge>
            <b-badge v-else variant="secondary">
              £180.00
            </b-badge>
          </b-row>
          <b-row class="mt-1">
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum       dolore eu fugiat nulla pariatur.
          </b-row>
          <hr/>
          <b-row class="mt-1 " v-if="selectedCell.state ==='Vacant'">
            <div class="mt-0">
              <span>Published on {{momentFormat(selectedCell.created_at,'DD/MM/YYYY')}} </span>
            </div>
            <div class="float-right ml-3">
              <b-button  @click="applyVacantShift(selectedCell.Id)"  size="sm" variant="primary">Apply Vacant Shift</b-button>
            </div>

          </b-row>

        </div>
      </div>
    </div>
</template>

<script>
import MomentMixin from '@/mixins/MomentMixin'
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BButton,
  BSidebar,
  BModal, BAlert,
  BBadge,
  BButtonGroup, VBToggle, VBModal,
} from 'bootstrap-vue'
import SideBar from "@/views/scheduler/sideBar";
import Ripple from 'vue-ripple-directive'
import shift from "@/apis/modules/shift";

  export default {
    name: 'content',
    mixins: [MomentMixin],
    components: {
      SideBar,
      BRow,
      BCol,
      BCard,
      BCardText,
      BLink,
      BCardTitle,
      BListGroup,
      BListGroupItem,
      BCardBody,
      BFormGroup,
      BButton,
      BSidebar,
      BModal,
      BAlert,
      BBadge,
      BButtonGroup,
    },
    directives: {
      Ripple,
    },
    data () {
      return {
        selectedCell:[],
        fields: { text: 'CalendarName', value: 'CalendarId' },
        roomData: [
          { CalendarName: 'My Calendar', CalendarId: 1, CalendarColor: '#c43081' },
          { CalendarName: 'Company', CalendarId: 2, CalendarColor: '#ff7f50' },
          { CalendarName: 'Birthday', CalendarId: 3, CalendarColor: '#AF27CD' },
          { CalendarName: 'Holiday', CalendarId: 4, CalendarColor: '#808000' }
        ],
        data: {}
      }
    },
    methods: {
      async viewApplications(){
        this.$root.$emit('indexComponentFormEventTemplateViewApplication') //like this
      },
      async applyVacantShift(id){
        try {
          const response =  await shift.applyVacantshifts(id)
          this.showSuccessMessage('Applied successfully')
          this.$refs.table.refresh()

        }catch (error){
          this.convertAndNotifyError(error)
        }
      },


    },
    mounted () {
      this.selectedCell = this.data
    }
  }
</script>

<style>
.applicationIcon{
  background-color: black; color: white ; border-radius: 5px ; padding: 2px
}
.applicationText{
  padding: 2px;
  margin-left: 5px;
  color:  #1B9AAA;
}
.applications{
  cursor: pointer;
}


</style>
