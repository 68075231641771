<template>
   <div></div>

</template>

<script>
  export default {
    name: 'footer',
    data() {
      return {
        data: {}
      };
    },
  }
</script>

<style scoped>

</style>
