<template >

     <b-sidebar
         id="add-new-user-sidebar"
         :visible="isAddNewShiftSidebarActive"
         backdrop
         bg-variant="white"
         no-header
         right
         shadow
         sidebar-class="sidebar-lg"
         @change="(val) => $emit('update:is-add-new-shift-sidebar-active', val)"

     >
       <template #default="{ hide }" >
       <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
           <h5 class="mb-0">
               Create Shift - Monday, February 22
           </h5>
           <feather-icon
                   class="ml-1 cursor-pointer"
                   icon="XIcon"
                   size="16"
                   @click="hide"
           />

       </div>
       <div class="p-2">
           <b-row>
               <b-col cols="12" md="6">
                   <b-form-group>
                       <label >Programme</label>

                       <v-select
                               v-model="selected"
                               label="title"
                               placeholder="Vaccination Programme"
                               :options="[
                            {title:'United Kingdom', value:'hdhdh'},
                            {title:'United States', value:'hdhdh'},
                            {title:'Sri Lanaka', value:'hdhdh'},
                            ]"
                       />
                   </b-form-group>
               </b-col>
               <b-col cols="12" md="6">
                   <b-form-group>
                       <label >Location</label>
                       <v-select
                               v-model="selected"
                               label="title"
                               placeholder="Select"
                               :options="[
                            {title:'United Kingdom', value:'hdhdh'},
                            {title:'United States', value:'hdhdh'},
                            {title:'Sri Lanka', value:'hdhdh'},
                            ]"
                       />
                   </b-form-group>
               </b-col>

           </b-row>
           <b-row>
               <b-col cols="12" md="6">
                   <b-form-group>
                       <label >Role</label>
                       <v-select
                               v-model="selected"
                               label="title"
                               placeholder="Health and wellbeing coach"
                               :options="[
                            {title:'Doctor', value:'hdhdh'},
                            {title:'Nurse', value:'hdhdh'},
                            {title:'Physician', value:'hdhdh'},
                            ]"
                       />
                   </b-form-group>
               </b-col>
               <b-col cols="12" md="6">
                   <b-form-group>
                       <label >Healthcare Professional</label>
                       <v-select
                               v-model="selected"
                               label="title"
                               placeholder="Bessie Cooper"
                               :options="[
                            {title:'Doctor', value:'hdhdh'},
                            {title:'Nurse', value:'hdhdh'},
                            {title:'Physician', value:'hdhdh'},
                            ]"
                       />
                   </b-form-group>
               </b-col>
           </b-row>
           <b-form-checkbox
                   v-model="selected"
                   value="A"
           >
               Vacant Shift
           </b-form-checkbox>
           <div class="mt-1">
               <b-form-group>
                   <label >Shift Type</label>
                   <v-select
                           v-model="selected"
                           label="title"
                           placeholder="Select"
                           :options="[
                            {title:'Example 1', value:'hdhdh'},
                            {title:'Example 2', value:'hdhdh'},
                            {title:'Example 3', value:'hdhdh'},
                            ]"
                   />
               </b-form-group>
           </div>
           <b-form-checkbox
                   v-model="selected"
                   value="A"
           >
               COVID-19 Shift
           </b-form-checkbox>

           <div class="mt-1">
               <b-row>
                   <b-col cols="12" md="6">
                       <label >Start Time</label>
                       <!--                    <v-select-->
                       <!--                            v-model="selected"-->
                       <!--                            label="title"-->
                       <!--                            placeholder="Select"-->
                       <!--                            :options="[-->
                       <!--                            {title:'Example 1', value:'hdhdh'},-->
                       <!--                            {title:'Example 2', value:'hdhdh'},-->
                       <!--                            {title:'Example 3', value:'hdhdh'},-->
                       <!--                            ]"-->
                       <!--                    />-->
                       <b-form-group>
                           <flat-pickr
                                   v-model="timePicker"
                                   class="form-control"
                                   :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
                           />
                       </b-form-group>
                   </b-col>
                   <b-col cols="12" md="6">
                       <label >End Time</label>
                       <!--                    <v-select-->f
                       <!--                            v-model="selected"-->
                       <!--                            label="title"-->
                       <!--                            placeholder="Select"-->
                       <!--                            :options="[-->
                       <!--                            {title:'Example 1', value:'hdhdh'},-->
                       <!--                            {title:'Example 2', value:'hdhdh'},-->
                       <!--                            {title:'Example 3', value:'hdhdh'},-->
                       <!--                            ]"-->
                       <!--                    />-->
                       <b-form-group>
                           <flat-pickr
                                   v-model="timePicker"
                                   class="form-control"
                                   :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
                           />
                       </b-form-group>
                   </b-col>
               </b-row>
           </div>
           <div class="mt-1">
               <h5>+ Add Break</h5>
           </div>
           <div class="mt-1">
               <b-row>
                   <b-col cols="12" md="4">
                       <b-form-checkbox
                               v-model="selected"
                               value="A"
                       >
                           Salaried Staff
                       </b-form-checkbox>


                   </b-col>
                   <b-col cols="12" md="8">
                       <b-form-checkbox
                               v-model="selected"
                               value="A"
                               disabled
                       >
                           Salaried Staff and Locums
                       </b-form-checkbox>
                   </b-col>
               </b-row>
           </div>
           <div class="mt-1">
               <b-row>
                   <b-col cols="12" md="4">
                       <b-form-checkbox
                               v-model="selected"
                               value="A"
                       >
                           Standard
                       </b-form-checkbox>
                   </b-col>
                   <b-col cols="12" md="4">
                       <b-form-checkbox
                               v-model="selected"
                               value="A"
                               disabled
                       >
                           Enhanced
                       </b-form-checkbox>
                   </b-col>
                   <b-col cols="12" md="4">
                       <b-form-checkbox
                               v-model="selected"
                               value="A"
                               disabled
                       >
                           Locum
                       </b-form-checkbox>
                   </b-col>
               </b-row>
           </div>
           <div class="mt-1">
               <label >Notes (optional)</label>
               <b-form-textarea
                       id="textarea-default"
                       placeholder="Textarea"
                       rows="3"
               />
           </div>
           <div class="flex mt-1">
               <b-button
                       v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                       variant="primary"
               >
                   Save
               </b-button>
               <b-button
                       v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                       variant="white"
               >
                   Cancel
               </b-button>
           </div>

       </div>
       </template>
     </b-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BButton,
  BSidebar,
  BFormCheckbox,
  BFormTextarea, VBToggle, VBModal
} from 'bootstrap-vue'
  import flatPickr from 'vue-flatpickr-component'
import Ripple from "vue-ripple-directive";

  export default {
    name: 'sideBar',
    components: {
      BRow,
      BCol,
      BCard,
      BCardText,
      BLink,
      BCardTitle,
      BListGroup,
      BListGroupItem,
      BCardBody,
      BFormGroup,
      BButton,
      BSidebar,
      BFormCheckbox,
      BFormTextarea,
      flatPickr
    },
    directives: {
      Ripple,
      'b-toggle': VBToggle,
      'b-modal': VBModal,
    },
    data () {
      return {
        selected:'',
        timePicker:''
      }
    },
    model: {
      prop: 'isAddNewShiftSidebarActive',
      event: 'update:is-add-new-shift-sidebar-active'
    },
    props:{
      isAddNewShiftSidebarActive: {
        type: Boolean,
        required: true
      },
    },

  }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
