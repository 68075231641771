<template>
  <div>
    <!--    <b-row>-->
    <!--      <b-col cols="12" md="3">-->

    <!--      </b-col>-->
    <!--      <b-col cols="12" md="3">-->

    <!--      </b-col>-->
    <!--      <b-col cols="12" md="3">-->


    <!--      </b-col>-->

    <!--      <b-col cols="12" md="3">-->
    <!--        <div class="flex float-right">-->

    <!--          <b-button-->
    <!--              v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
    <!--              class="btn-icon mr-1"-->
    <!--              variant="lighten-2"-->
    <!--              @click="printData()"-->

    <!--          >-->
    <!--            <feather-icon icon="PrinterIcon"/>-->
    <!--          </b-button>-->


    <!--        </div>-->
    <!--      </b-col>-->
    <!--      <b-col cols = 12 md="12" class="mt-2">-->
    <!--      </b-col>-->
    <!--    </b-row>-->


    <b-card>
      <b-row>
<!--        <b-col cols="3">-->
<!--          <b-form-group>-->
<!--            <b-input-group class="input-group-merge">-->
<!--              <b-input-group-prepend is-text>-->
<!--                <feather-icon icon="SearchIcon"/>-->
<!--              </b-input-group-prepend>-->
<!--              <b-form-input-->
<!--                  id="icons-search"-->
<!--                  placeholder="Search"-->
<!--              />-->
<!--            </b-input-group>-->
<!--          </b-form-group>-->

<!--        </b-col>-->
<!--        <b-col cols="3">-->
<!--          <v-select-->
<!--              v-model="shiftsFilter"-->
<!--              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--              :options="[-->
<!--                            {title:'All', value:''},-->
<!--                            {title:'Vacant', value:'Vacant'},-->
<!--                            {title:'Filled', value:'UpComing'},-->
<!--                            {title:'Pending', value:'Pending'}-->
<!--                            ]"-->
<!--              class="mb-2"-->
<!--              label="title"-->
<!--              placeholder="All Shift"-->
<!--          />-->
<!--        </b-col>-->
<!--        <b-col cols="3">-->
<!--          <b-form-group>-->
<!--            <v-select-->
<!--                v-model="filter"-->
<!--                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--                :options="[-->
<!--                            {title:'The London General Practice', value:'hdhdh'},-->
<!--                            {title:'Tottenham Hale Medical Practice', value:'hdhdh'},-->
<!--                            {title:'Merton Medical Practice', value:'hdhdh'},-->
<!--                            {title:'Clerkenwell Primary Care Network', value:'hdhdh'},-->
<!--                            ]"-->
<!--                class="mb-2"-->
<!--                label="title"-->
<!--                placeholder="All Locations"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->

        <b-col cols="12">

          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="btn-icon m-1 float-right "
              variant="primary"
              @click="$router.push('/shifts/vacant')"
          >Vacant Shifts
          </b-button>
        </b-col>


      </b-row>

      <div class="schedule-vue-sample">
        <div class="content-wrapper">
          <ejs-schedule
              ref="scheduler"
              :dataBinding="dataBinding"
              :drag="false"
              :eventClick="onEventClick"
              :eventRendered="onEventRendered"
              :eventSettings='eventSettings'
              :popupOpen="openpopup"
              :quickInfoTemplates="quickInfoTemplates"
              :selectedDate='selectedDate'
              :timeScale="timeScale"
              :workDays="workDays"
              height="auto"
          >
            <e-views>
              <!--                   <e-view  option="Week"/>-->
              <!--                   <e-view  option="Month"/>-->
              <!--                   <e-view :eventTemplate="weekTemplate"  displayName="DAY" option="TimelineDay"/>-->
              <e-view :eventTemplate="weekTemplate" displayName="WEEK" option="Week"/>
              <e-view :eventTemplate="monthTemplate" displayName="MONTH" option="Month"/>
            </e-views>
            <!--            <e-views>-->
            <!--              <e-view option="TimelineWeek" />-->
            <!--              <e-view option="TimelineMonth" />-->
            <!--              &lt;!&ndash;                        <e-view option="TimelineWorkWeek" />&ndash;&gt;-->
            <!--              &lt;!&ndash;                        <e-view option="TimelineMonth" />&ndash;&gt;-->
            <!--              &lt;!&ndash;                        <e-view option="Agenda" />&ndash;&gt;-->
            <!--            </e-views>-->

          </ejs-schedule>
        </div>
      </div>

      <!--         </div>-->

    </b-card>
    <b-modal
        id="modal-center"
        v-model="showApplyVacantModel"
        centered

        hide-footer
        no-close-on-backdrop
        size="xl"
        title="Vacant Shifts"
    >
      <b-overlay
          :show="vacantShiftsLoading"
          rounded="sm"
      >

        <b-row class="mb-1">
          <b-col cols="12">
            <b-alert
                show
                variant="primary"
            >
              <div class="alert-body">
                <feather-icon
                    class="mr-25"
                    icon="AlertCircleIcon"
                />
                <span class="ml-25">All listed shifts are applicable to your role only.</span>
                <!--                <span class="ml-25">All listed shifts are applicable to your role only: Occupational Therapist.</span>-->
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="4">
            <b-form-group>
              <flat-pickr
                  v-model="selectedDateForFilter"
                  :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d',}"
                  class="form-control"
                  placeholder="select Data"
              />
            </b-form-group>
          </b-col>

          <b-col cols="8">
            <div class="flex float-right">

              <b-form-checkbox
                  checked="true"
                  class="custom-control-primary"
                  name="check-button"
                  switch
              >
        <span class="">
<!--          <feather-icon icon="CheckIcon" />-->
          <!--        </span>-->
          <!--                <span class="switch-icon-right">-->
          <!--          <feather-icon icon="XIcon" />-->
        </span>
                <div>Show vacant shifts on my calendar</div>

              </b-form-checkbox>

            </div>

          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">

            <b-table
                ref="tableApply"
                :current-page="currentPage"
                :eventClick="onEventClick"
                :eventDoubleClick="false"
                :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                :items="getVacantShiftApplications"
                :per-page="pagination.perPage"
                :popupOpen="openpopup"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"

                :sort-direction="sortDirection"
                class="mobile_table_css"
                hover
                responsive
            >


              <template #cell(action)="data">
                <b-button variant="primary" @click="applyVacantShifts(data.item.id)">Apply</b-button>


              </template>

            </b-table>
          </b-col>
          <b-col v-if="noDataTable === 0" class="text-center" cols="12">
            <span>No data for preview</span>
          </b-col>
          <!-- Pagination -->

          <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
            <span v-if="pagination.totalRows !==0"
                  class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.totalRows }} entries</span>

          </b-col>

          <!-- Pagination -->

          <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

            <b-pagination
                v-model="currentPage"
                :per-page="pagination.perPage"
                :total-rows="pagination.totalRows"
                class="mb-0 mt-1 mt-sm-0 "
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item">

              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>

            </b-pagination>

          </b-col>
        </b-row>


      </b-overlay>

    </b-modal>


  </div>


</template>

<script>

import {
  BAlert,
  BCard,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox, BFormDatepicker, BFormInvalidFeedback, BFormTextarea,
  BInputGroupPrepend, BOverlay, BSidebar,
  BTab,
  BTabs,
  VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import quickInfoTContentmplate from './eventView/content'
import quickInfoHeaderTemplate from './eventView/header'
import quickInfoFooterTemplate from './eventView/footer'

import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,

} from 'bootstrap-vue'
// import settings from '@/apis/modules/settings'
// import shift from '@/apis/modules/shift'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import MomentMixin from '@/mixins/MomentMixin'
import vSelect from 'vue-select'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Vue from "vue";
import {extend} from '@syncfusion/ej2-base';
import {
  SchedulePlugin,
  Week,
  Month,
  DragAndDrop,
  Agenda,
  TimelineViews,
  TimelineMonth, Resize, Print
} from '@syncfusion/ej2-vue-schedule'
import {DatePickerPlugin, ChangeEventArgs} from '@syncfusion/ej2-vue-calendars';
// import leave from '@/apis/modules/leave'
Vue.use(SchedulePlugin);
Vue.use(DatePickerPlugin);
import flatPickr from 'vue-flatpickr-component'
// import practice from '@/apis/modules/practice'
import shift from "@/apis/modules/shift";
import moment from "moment";
import weekTemplate from '@/views/shifts/eventsTemplates/weekTemplate'
import monthTemplate from '@/views/shifts/eventsTemplates/monthTemplate'


export default {
  components: {
    flatPickr,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    vSelect,
    BInputGroupPrepend,
    BCard,
    BTabs,
    BTab,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormInvalidFeedback,
    BSidebar,
    BFormTextarea,
    VBTooltip,
    BOverlay,


  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin],

  data() {
    return {
      shiftsFilter: '',
      query: {
        value: '',
        start: null,
        end: null
      },
      swapRequestListLength: 0,
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [

        {
          key: 'date',
          label: 'Date',
        },
        {
          key: 'subject',
          label: 'SERVICE',
        },
        {
          key: 'time',
          label: 'Time',
        },
        {
          key: 'rate',
          label: 'Rate',
        },

        {
          key: 'action',
          label: ' ',
        },


        // {
        //   key: 'action',
        //   label: '',
        // },

      ],

      noDataTable: '',
      showSwapRequestModel: false,
      swapRequestList: [],
      swapRequestLoading: false,
      selectedDateForFilter: '',
      showApplyVacantModel: false,
      vacantShiftsLoading: false,
      eventSettings: {dataSource: ''},
      selectedDate: new Date(),
      items: [],
      currentView: 'Week',
      timeScale: {
        enable: true,
        interval: 60,
        slotCount: 2

      },
      dayOfWeekValue: '1',
      workDays: [1, 2, 3, 4, 5],

      weekTemplate() {
        return {template: weekTemplate}

      },
      monthTemplate() {
        return {template: monthTemplate}

      },
      quickInfoTemplates: {
        header() {
          return {template: quickInfoHeaderTemplate}
        },
        content() {
          return {template: quickInfoTContentmplate}
        },
        footer() {
          return {template: quickInfoFooterTemplate}
        }
      }
    }
  },
  provide: {
    schedule: [Agenda, TimelineViews, Month, TimelineMonth, Week, Resize, DragAndDrop, Print]

  },
  watch: {
    eventSettings() {

    },
    'query.value'() {
      this.myShifts()

    },
    shiftsFilter(){
      this.eventSettings.dataSource = this.eventSettings.dataSource.filter(m => m.state=`${this.shiftsFilter.value}`)

      console.log(this.eventSettings.dataSource)
    }


  },
  methods: {
    dataBinding(val) {
      const start_date = moment(val.query.params.find(x => x.key === 'StartDate').value).format('YYYY-MM-DD')
      const end_date = moment(val.query.params.find(x => x.key === 'EndDate').value).format('YYYY-MM-DD')
      this.query.start = start_date
      this.query.end = end_date
      this.query.value = `${start_date}_${end_date}`

    },
    async applyVacantShifts(id) {
      try {
        // await shift.applyVacantShift(id)
        this.showSuccessMessage('shift apply successful')
        this.$refs.tableApply.refresh()
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    async getVacantShiftApplications() {
      try {
        // const Response = await shift.getApplicationListVacantShifts()
        this.noDataTable = Response.data.data.length

        let dataArray = Response.data.data.map((x) => ({
          id: x.id,
          date: this.momentFormat(x.start, 'dddd YYYY-MM-DD'),
          subject: x.service === null ? 'N/A' : x.service.name,
          rate: 'N/A',
          time: `${this.momentFormat(x.start, 'HH:mm')}` + ' ' + `${this.momentFormat(x.end, 'HH:mm')}`
        }))

        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to

        return dataArray


      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    openApplyShiftsModel() {
      this.showApplyVacantModel = !this.showApplyVacantModel


    },
    openpopup(agrs) {
      if (!agrs.data.Guid) {
        agrs.cancel = true
        this.selectedCell = agrs
        this.createShift()
      }
    },
    async onPopUpOpen(agrs) {

    },

    async onEventClick(agrs) {
      this.selectedCell = await agrs
    },
    async onCellClick(agrs) {
      agrs.cancel = true
      this.selectedCell = agrs
    },

    onEventRendered(args) {
      args.element.style.backgroundColor = '#00C49A'
    },
    onDateChange: function (args) {
      this.selectedDate = args.value;
    },
    async myShifts() {
      try {
        const myShiftsResponse = await shift.myShift(this.query.start, this.query.end)
        const vacantShifts = await shift.vacantShiftForCalender(this.query.start, this.query.end)
        const myAppliedShifts = await shift.appliedShiftForCalender(this.query.start, this.query.end)

        const mySifts = await this.shiftMapFunction(myShiftsResponse.data.data, '#00C49A', 'Upcoming')
        const myAppliedSifts = await this.shiftMapFunction(myAppliedShifts.data.data, '#EFA12C', 'Pending')
        if (true) {
          const vacantShifts = await shift.vacantShiftForCalender(this.query.start, this.query.end)
          const myVacantSifts = await this.shiftMapFunction(vacantShifts.data.data, '#4361EE', 'Vacant')
          const finalDataMap = mySifts.concat(myVacantSifts).concat(myAppliedSifts)
          this.eventSettings = {dataSource: finalDataMap}
        } else {
          const finalDataMap = mySifts.concat(myAppliedSifts)
          this.eventSettings = {dataSource: finalDataMap}
        }
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },

    async shiftMapFunction(data, color, state) {

      const MappedData = await data.map((x) => ({
        Id: x.id,
        EndTime: new Date(x.end),
        StartTime: new Date(x.start),
        starTimeForEdit: new Date(x.start),
        endTimeForEdit: new Date(x.end),
        // user_id: x.assignee.id,
        // user:x.assignee,
        group_id: x.job_role.id,
        Subject: x.service === null ? '' : x.service.name,
        service: x.service === null ? [] : x.service,
        shift_type: x.shift_type === null ? [] : x.shift_type,
        state: state,
        job_role: x.job_role,
        break_minutes: x.break_minutes,
        note: x.note,
        rate: x.rate,
        IsAllDay: false,
        is_covid_shift: x.is_covid_shift,
        practice: x.practice,
        created_at: x.created_at,
        color: color

      }))
      return MappedData
    }

  },
  async mounted() {
    // sessionStorage.setItem('practice_id', this.$route.query.practice_id)
    this.$refs.scheduler.ej2Instances.firstDayOfWeek = 1
  },


}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
/*print style*/
@import '@core/scss/vue/libs/vue-flatpicker.scss';


</style>
<style>


.template-wrapper {
  padding: 0 !important;
}
div#dropdown-1 .btn-outline-danger {
  border: none !important;
}

.e-appointment e-lib e-draggable {
  width: 100% !important;
}

[dir] .btn.btn-icon {
  padding: 0.715rem 0.736rem;
  background-color: white;
}

[dir] .e-quick-popup-wrapper .e-event-popup .e-popup-header {
  background-color: white;
}


[dir=ltr] .vs--single .vs__dropdown-toggle {
  background-color: white;

}

/* .e-resource-column{
  table-layout: fixed !important;
  width: 15% !important;
  overflow: hidden !important;
} */

.e-schedule .template-wrap {

padding: 0px !important;

}

.swapButton {
  background-color: white;
}


</style>
